
import { defineComponent, ref } from 'vue'
import { formatDate } from '@/services/dateTimeService'
import ContactsListsTable from '@/components/pages/contacts/lists/ContactsListsTable.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: {
    ContactsListsTable,
    TmButton,
    TmModal,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const selected = ref<any[]>([props.items[0]])

    const listsHeaders = ref([
      {
        text: 'List name',
        align: 'start',
        value: 'list-name',
        required: true,
        field: (row: any) => row.listName,
      },
      { text: 'Contacts', value: 'contacts' },
      { text: 'Tags', value: 'tags', width: '25%', hideSortable: true },
      { text: 'Last updated', value: 'lastUpdated', width: '15%', format: (val: string) => formatDate(val) },
      { text: 'Date created', value: 'dateCreated', width: '15%', format: (val: string) => formatDate(val) },
    ])
    return {
      listsHeaders,
      selected,
    }
  },
})
